<template>
  <div>
    <h3 class="ma-10 mt-5 mb-2 text-aling text-left">
      Creazione ordine da prodotti disponibili
    </h3>

    <v-row>
      <v-col>
        <v-card
          class="drop-zone"
          style="overflow-y: scroll"
          @drop="addToOrderPreview($event)"
          @dragover.prevent
          @dragenter.prevent
          color=""
        >
          <v-card-title>
            <h5 class="text-aling text-center">Prodotti Disponibili</h5>
          </v-card-title>

          <v-card-text>
            <div
              class="d-flex flex-column justify-center align-center"
              style="width: 100%"
            >
              <v-row class="mb-3">
                <v-col>
                  <v-select
                    :rules="[presenceRule]"
                    v-model="supplierSelected"
                    :items="suppliers"
                    item-value="id"
                    item-text="businessName"
                    return-object
                    label="Seleziona Fornitore"
                    filled
                    deletable-chips
                    dense
                    chips
                    small-chips
                    hide-details="auto"
                    prepend-icon="mdi-account-multiple"
                  ></v-select>
                </v-col>
              </v-row>

              <slot
                class="d-flex flex-column align-center justify-center"
                name="loader"
                :value="loading"
                v-if="loading"
                v-bind="{ loading: loading }"
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </slot>
            </div>
            <v-chip
              large
              class="drag-el"
              v-for="item in itemsAvailableForOrder"
              :key="item.title"
              draggable
              @dragstart="startDrag($event, item)"
              @click="addToOrderPreview(null, item)"
            >
              <div class="d-flex flex-column">
                <h5 class="text-aling text-center">
                  {{ item.code }}
                </h5>
                {{ item.description }}
              </div>
            </v-chip>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="1" class="d-flex flex-column align-center justify-center">
        <v-btn
          :disabled="canMoveRight"
          class="mb-5"
          fab
          @click="moveFirstItemInNewOrder"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn fab :disabled="canMoveLeft" @click="removeLastItemInNewOrder">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-card
          style="overflow-y: scroll"
          class="drop-zone"
          @dragover.prevent
          @dragenter.prevent
        >
          <v-card-title>
            <h5 class="text-aling text-center">Nuovo Ordine</h5>
          </v-card-title>
          <v-card-text class="body">
            <slot name="content">
              <div
                class="d-flex flex-column justify-center align-center"
                style="width: 100%"
              >
                <slot
                  class="d-flex flex-column align-center justify-center"
                  name="loader"
                  :value="loading"
                  v-if="loading"
                  v-bind="{ loading: loading }"
                >
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </slot>
              </div>

              <v-chip
                large
                class="drag-el"
                v-for="item in itemsSelectedForNewOrder"
                :key="item.title"
              >
                <div class="d-flex flex-column">
                  <h5 class="text-aling text-center">
                    {{ item.code }}
                  </h5>
                  {{ item.description }}
                </div>
              </v-chip>
            </slot>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="flex-grow-0 footer">
            <v-spacer></v-spacer>
            <slot name="footer-actions">
              <v-btn
                :disabled="canMoveLeft"
                text
                color="primary"
                @click="openPreview"
                >Crea Ordine</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="ma-4"></v-divider>
    <h3 class="ml-10 text-aling text-left">
      <v-icon class="mr-4" large>mdi-format-list-text</v-icon>
      Lista Ordini
    </h3>

    <OrderDataTable
      :show-actions="false"
      :loading="loading"
      :items="lastOrders"
      :headers="headers"
      :show-select="false"
    >
    </OrderDataTable>

    <OrderCreateDialog
      v-if="openOrderCreateDialog"
      :items-selected="itemsSelectedForNewOrder"
      :open-dialog="openOrderCreateDialog"
      @close="openOrderCreateDialog = false"
      @saved-order="saveOrder"
    >
    </OrderCreateDialog>
  </div>
</template>
<script>
import OrderCreateDialog from "./../../orders/new/OrderCreateDialog.vue";
import { SearchBar } from "likablehair-ui-components";
import OrderDataTable from "@/components/common/TypeDataTable.vue";
import itemService from "@/services/warehouse/items.service.js";
import orderService from "@/services/warehouse/orders.service.js";
import suppliersService from "@/services/suppliers/suppliers.service.js";

export default {
  components: {
    OrderDataTable,
    SearchBar,
    OrderCreateDialog,
  },
  data: function () {
    return {
      loading: false,
      openOrderCreateDialog: false,
      itemsAvailableForOrder: [],
      itemsSelectedForNewOrder: [],
      lastOrders: [],

      supplierSelected: undefined,
      suppliers: [],
      suppliersObject: [],
      headers: [
        
        {
          text: "Codice interno",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Prezzo Totale di Vendita",
          value: "priceTotSelling",
        },
        {
          text: "Prezzo Totale d'acquisto",
          value: "priceTotPurchase",
        },
        {
          text: "Prezzo Totale Netto d'acquisto",
          value: "priceTotNetPurchase",
        },
        {
          text: "Data Creazione",
          value: "createdAt",
          type: "date",
        },
      ],
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
    };
  },
  mounted() {
    this.fetchSuppliers();
    // this.fetchItems({ supplierId: null });
    this.loadLastOrder();
  },
  computed: {
    canMoveRight() {
      if (this.itemsAvailableForOrder.length == 0) return true;
      else return false;
    },
    canMoveLeft() {
      if (this.itemsSelectedForNewOrder.length == 0) return true;
      else return false;
    },
  },
  methods: {
    fetchSuppliers() {
      return new Promise(async (resolve, reject) => {
        suppliersService
          .list()
          .then((results) => {
            this.suppliers = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    openPreview() {
      this.openOrderCreateDialog = true;
    },
    fetchItems(filters) {
      this.loading = true;
      itemService.list(0, 0, filters).then((results) => {
        this.itemsAvailableForOrder = results.rows;
        this.loading = false;
      });
    },
    loadLastOrder() {
      orderService.list().then((results) => {
        this.lastOrders = results.rows;
      });
    },
    moveFirstItemInNewOrder() {
      let firstItem = this.itemsAvailableForOrder[0];
      const index = this.itemsAvailableForOrder.findIndex(
        (item) => item.id == firstItem.id
      );
      if (index > -1) {
        this.itemsAvailableForOrder.splice(index, 1);
      }
      this.itemsSelectedForNewOrder.push(firstItem);
    },
    removeLastItemInNewOrder() {
      let lastItem =
        this.itemsSelectedForNewOrder[this.itemsSelectedForNewOrder.length - 1];
      this.itemsSelectedForNewOrder.pop();
      this.itemsAvailableForOrder.push(lastItem);
    },
    startDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    addToOrderPreview(evt, itemClicked) {
      if (evt) {
        const itemID = evt.dataTransfer.getData("itemID");
        const item = this.itemsAvailableForOrder.find(
          (_item) => _item.id == itemID
        );
        const index = this.itemsAvailableForOrder.findIndex(
          (_item) => _item.id == itemID
        );
        if (index > -1) {
          this.itemsAvailableForOrder.splice(index, 1);
        }

        this.itemsSelectedForNewOrder.push(item);
      } else {
        this.itemsSelectedForNewOrder.push(itemClicked);
      }
    },
    saveOrder() {
      this.openOrderCreateDialog = false;
      this.itemsSelectedForNewOrder = [];
      this.loading = true;
      this.loadLastOrder();
      this.loading = false;
    },
  },
  watch: {
    supplierSelected(newVal) {
      if (newVal) {
        this.itemsSelectedForNewOrder = [];

        this.fetchItems({ supplierId: newVal.id });
      } else {
        this.itemsAvailableForOrder = [];
        this.itemsSelectedForNewOrder = [];
      }
    },
  },
};
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  margin: 10px;
  padding: 10px;
  height: 450px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  margin-left: 3px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.footer {
  height: fit-content;
}
.body {
  flex: 0 0 auto;
  flex-shrink: 1;
  flex-grow: 1;
}
</style>